<template>
  <div class="profile">
    <div class="sidebar">
      <div class="text-center">
        <router-link :to="{ name: 'main' }">
          <img
            src="../assets/logo-2-dark.png"
            alt="To Be Legacy"
            class="logo"
          >
        </router-link>
        <b-img
          v-if="userData.profile_picture"
          :src="userData.profile_picture"
          rounded="circle"
          alt
          class="img-fluid p-3"
        />
        <b-img
          v-else
          :src="require('../assets/avatar-default.png')"
          rounded="circle"
          width="176"
          alt
          class="img-fluid p-3"
          style="cursor: pointer"
          @click="$router.push({ name: 'edit_account' })"
        />
        <p style="font-weight: 500;">
          {{ userData.first_name }} {{ userData.last_name }}
        </p>
      </div>

      <hr>

      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/profile/' }"
            :to="{ name: 'main' }"
          >
            Legacy Library
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/journals' }"
            :to="{ name: 'journals' }"
          >
            My Journals
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/program/living_in_excellence' }"
            :to="{ name: 'living_in_excellence' }"
          >
            Living in Excellence
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/ask-a-coach' }"
            :to="{ name: 'ask_a_coach' }"
          >
            Ask A Coach
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/page/apply-for-personal-coaching' }"
            :to="{ name: 'flatpage', params: { slug: 'apply-for-personal-coaching' }}"
          >
            Apply for Personal Coaching
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link disabled"
            :class="{ 'active': $router.currentRoute.fullPath === '/store-and-media' }"
            :to="{ name: 'main' }"
          >
            Store/Media
          </router-link>
        </li>
      </ul>

      <hr>

      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/page/partner-with-us' }"
            :to="{ name: 'flatpage', params: { slug: 'partner-with-us' }}"
          >
            Partner With Us
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/page/tbl-live-events' }"
            :to="{ name: 'flatpage', params: { slug: 'tbl-live-events' }}"
          >
            TBL Live Events
          </router-link>
        </li>
      </ul>

      <hr>

      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/edit_account' }"
            :to="{ name: 'edit_account' }"
          >
            Manage Account
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="{ 'active': $router.currentRoute.fullPath === '/logout' }"
            :to="{ name: 'logout' }"
          >
            Logout
          </router-link>
        </li>
      </ul>
    </div>

    <div class="mobile-navbar">
      <router-link :to="{ name: 'main' }">
        <img
          src="../assets/logo-2-dark.png"
          alt="To Be Legacy"
          class="logo"
        >
      </router-link>
      <nav class="navbar navbar-light background-primary d-flex">
        <b-button
          v-b-toggle.collapse-1
          class="navbar-toggler bg-white mt-0"
        >
          <span class="navbar-toggler-icon" />
        </b-button>
        <div>
          <p
            style="font-weight: 500;"
            class="text-white mb-0 d-inline mr-2"
          >
            {{ userData.first_name }} {{ userData.last_name }}
          </p>
          <b-img
            v-if="userData.profile_picture"
            :src="userData.profile_picture"
            rounded="circle"
            alt
            class="img-fluid"
            style="width: 50px;"
          />
          <b-img
            v-else
            src="../assets/avatar-default.png"
            rounded="circle"
            :width="100"
            alt
            class="img-fluid"
          />
        </div>
      </nav>
      <b-collapse
        id="collapse-1"
        v-model="visible"
        class="background-gradient"
      >
        <div>
          <ul class="nav flex-column">
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/profile/' }"
                :to="{ name: 'main' }"
              >
                Legacy Library
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/journals' }"
                :to="{ name: 'journals' }"
              >
                My Journals
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/program/living_in_excellence' }"
                :to="{ name: 'living_in_excellence' }"
              >
                Living in Excellence
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/ask-a-coach' }"
                :to="{ name: 'ask_a_coach' }"
              >
                Ask A Coach
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/page/apply-for-personal-coaching' }"
                :to="{ name: 'flatpage', params: { slug: 'apply-for-personal-coaching' }}"
              >
                Apply for Personal Coaching
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link disabled"
                :class="{ 'active': $router.currentRoute.fullPath === '/store-and-media' }"
                :to="{ name: 'main' }"
              >
                Store/Media
              </router-link>
            </li>
          </ul>

          <hr>

          <ul class="nav flex-column">
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/page/partner-with-us' }"
                :to="{ name: 'flatpage', params: { slug: 'partner-with-us' }}"
              >
                Partner With Us
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/page/tbl-live-events' }"
                :to="{ name: 'flatpage', params: { slug: 'tbl-live-events' }}"
              >
                TBL Live Events
              </router-link>
            </li>
          </ul>

          <hr>

          <ul class="nav flex-column">
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/edit_account' }"
                :to="{ name: 'edit_account' }"
              >
                Manage Account
              </router-link>
            </li>
            <li
              class="nav-item"
              @click="visible=false"
            >
              <router-link
                class="nav-link"
                :class="{ 'active': $router.currentRoute.fullPath === '/logout' }"
                :to="{ name: 'logout' }"
              >
                Logout
              </router-link>
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>

    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import ProgramsMixin from '@/mixins/Programs.vue';
import CoursesMixin from '@/mixins/Courses.vue';

export default {
  name: 'Profile',
  mixins: [CoursesMixin, ProgramsMixin],
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    programs() {
      return this.$store.state.programs;
    },
  },
  watch: {
    async $route() {
      await this.fetchCourses();
      await this.fetchPrograms();
    },
  },
  async mounted() {
    await this.fetchCourses();
    await this.fetchPrograms();
  },
};
</script>
