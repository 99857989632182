<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async fetchPrograms() {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}programs/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.loading = false;

      if (response && response.status === 200) {
        const responseJson = await response.json();

        responseJson.forEach((p) => {
          if (this.userData.programs) {
            const enrollments = this.userData.programs.filter(up => up.title === p.title);
            if (enrollments.length) {
              p.purchased = true;
              p.enrollments = enrollments;
              p.active_enrollment = null;
              enrollments.forEach((e) => {
                if (['active', 'permanent_access'].includes(e.status)) {
                  p.active_enrollment = e;
                }
              });
            } else {
              p.purchased = false;
              p.enrollments = [];
            }
          }
        });

        this.$store.commit('programs', responseJson);
      }
    },
  },
};
</script>
